import { TableCell } from "@components/core/Table";
import { ArtworkAndControls } from "@components/tables/Tables.shared.style";
import styled, { css } from "styled-components";
import { PLAYER_ID } from "../TwitchLiveStream";

export const HEADER_ID = "twitch_livestream-tracklist_header";
const BODY_MOBILE_HEIGHT = 256; // px
const SEEQNC_COLOR = "#e2cc00";

const calculateTrackListHeight = (isDesktop: boolean | undefined) => {
	const playerHeight = isDesktop ? document.getElementById(PLAYER_ID)?.clientHeight : BODY_MOBILE_HEIGHT;
	const headerHeight = document.getElementById(HEADER_ID)?.clientHeight;
	const height = playerHeight && headerHeight ? `${playerHeight - headerHeight}px` : "auto";
	return height;
};

export const LiveStreamWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

export const LiveStreamTrackListWrapper = styled.div`
  width: 100%;

  @media ${(props) => props.theme.device.md} {
    width: 360px;
  }
`;

export const LiveStreamTrackListHeader = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 112px;
  padding: 8px;
  background: ${(props) => props.theme.colors.neutrals.primary["900"]};
`;

export const LiveStreamTrackListBody = styled.div<{ isDesktop?: boolean }>`
  width: 100%;
  background-color: ${(props) => props.theme.colors.neutrals.primary["600"]};
  overflow-y: auto;

  /* Styling Scrollbar */
  ::-webkit-scrollbar {
    width: 10px;
  }

  ::-webkit-scrollbar-track {
    background: ${(props) => props.theme.colors.neutrals.primary[600]}; 
  }
  
  ::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.colors.neutrals.primary[900]}; 
  }

  ::-webkit-scrollbar-thumb:hover {
    background: ${(props) => props.theme.colors.neutrals.secondary.gray.active}; 
  }

  ${({ isDesktop }) => css`
    height: ${calculateTrackListHeight(isDesktop)};
  `};
`;

export const EventTitle = styled.div`
  color: ${(props) => props.theme.colors.neutrals.primary.white};
  font-family: ${(props) => props.theme.fonts.aeonik};
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
  letter-spacing: -0.34px;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const CurrentPlayingTrack = styled.div`
  overflow: hidden;
  color: ${(props) => props.theme.colors.neutrals.primary.white};
  text-overflow: ellipsis;
  font-family: ${(props) => props.theme.fonts.aeonik};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.084px;
`;

export const TrackListPoweredBy = styled.div`
  overflow: hidden;
  color: ${(props) => props.theme.colors.neutrals.primary.graytext};
  text-align: right;
  text-overflow: ellipsis;
  font-family: ${(props) => props.theme.fonts.aeonik};
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 12px;

  div:nth-child(1) {
    display: flex;
    flex-direction: row;
    justify-content: end;
    a {
      color: ${SEEQNC_COLOR};
    }
    div:nth-child(1) {
      gap: 4px;
      display: flex;
      flex-direction: column;
      padding-right: 5px;
    }
  }
`;

export const AddToCartChild = styled.div<{ isAlreadyInCart?: boolean }>`
  padding: 8px;
  border-radius: 4px;
  stroke: ${(props) => props.theme.colors.neutrals.primary.white};
  background: ${(props) =>
			props.isAlreadyInCart ?
				props.theme.colors.neutrals.primary.graytext :
				props.theme.colors.pink["300"].accent
	};

  &:hover {
    cursor: pointer;
  }

  span.opaque-loader {
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;

    svg {
      width: 16px;
      height: 16px;
    }
  }
`;

export const TrackArtworkWrapper = styled(ArtworkAndControls) <{ width: number; height: number }>`
  ${(props) => css`
    min-width: ${props.width}px;
    min-height: ${props.height}px;
  `};
`;

export const LiveStreamTrackListTableCell = styled(TableCell)`
  padding: 0 8px;
`;

export const TrackListArtistNames = styled.span`
  > * {
    color: ${(props) => props.theme.colors.neutrals.primary.graytext};
  }
`;
