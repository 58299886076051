import ImageFallback from "@components/core/Image/ImageFallback";
import { Table, TableData, TableRow } from "@components/core/Table";
import { SpriteIcon } from "@components/core/icons/SpriteIcon";
import { AddToCart } from "@components/interaction";
import { ArtistNames } from "@components/shared/Artists/ArtistNames";
import { ReleaseName } from "@components/tables/Tables.shared.style";
import { TRACK_PLACEHOLDER } from "@lib/constants";
import { BEATPORT_URL } from "@lib/constants/urls";
import { useCart, useCartActions } from "@lib/context/cart";
import { TwitchLiveStreamStateContext } from "@lib/context/twitchLiveStream";
import { useMediaQuery } from "@lib/hooks/useMediaQuery";
import usePolling from "@lib/hooks/usePolling";
import { device } from "@styles/theme";
import axios from "axios";
import { getConfig } from "config";
import Image from "next/image";
import Link from "next/link";
import { useCallback, useContext, useEffect } from "react";

import {
	AddToCartChild,
	CurrentPlayingTrack,
	EventTitle,
	HEADER_ID,
	LiveStreamTrackListBody,
	LiveStreamTrackListHeader,
	LiveStreamTrackListTableCell,
	LiveStreamTrackListWrapper,
	LiveStreamWrapper,
	TrackArtworkWrapper,
	TrackListArtistNames,
	TrackListPoweredBy,
} from "./LiveStreamTrackList.style";
import { SeeqncTrack } from "./types";

const config = getConfig();

const IMG_WIDTH = TRACK_PLACEHOLDER.image.width;
const IMG_HEIGHT = TRACK_PLACEHOLDER.image.height;
const FALLBACK_URL = TRACK_PLACEHOLDER.href;
const TRACK_POLL_INTERVAL = 30000;

const fetchTracklist = async (
	{ url, apiKey, onSuccess, onError }:
	{ url: string; apiKey: string; onSuccess: (results: any) => any; onError: (results: any) => any },
) => {
	try {
		const tracklistVendorUrl = `${url}?apiKey=${apiKey}`;
		const response = await axios.get(tracklistVendorUrl);
		onError(null);
		return onSuccess(response.data);
	} catch (error) {
		onError(error);
		return [];
	}
};

const formatResults = (results: any) =>
	results
		.filter((track: any) => !!track.lastPlay.beatportUrl)
		.map((track: any) => ({
			...track,
			track_id: parseInt(track.lastPlay.beatportUrl.split("/")[5], 10),
		}));

const LiveStreamTrack: React.FC = () => {
	const { state: { currentStage, currentStream, isExpanded } } = useContext(TwitchLiveStreamStateContext);
	const apiKey = /* currentStage.tracklist_vendor?.api_key || */config.SEEQNC_API_KEY;

	const isDesktop = useMediaQuery({ query: device.md });
	const { state } = useCart();
	const { carts, cartDetails } = state;
	const { createCartQueries } = useCartActions();
	const { isTrackInCart } = createCartQueries(carts, cartDetails);

	const fetchTracklistMemoized = useCallback(
		async () => await fetchTracklist({
			url: currentStage.tracklist_vendor_url || "",
			apiKey,
			onSuccess: formatResults,
			onError: console.error,
		}),
		[apiKey, currentStage.tracklist_vendor_url],
	);

	const { data, isPollAlive, killPoll, revivePoll } = usePolling(
		{ asyncCallback: fetchTracklistMemoized, dependencies: [currentStage.tracklist_vendor_url], options: { interval: TRACK_POLL_INTERVAL } },
	);
	const tracks = data || [];
	const currentArtist = tracks.length > 0 ? tracks[0].performingArtist : "N/A";

	// ? Control polling based on isExpanded
	useEffect(() => {
		if (isExpanded && !isPollAlive) {
			revivePoll();
		} else if (!isExpanded && isPollAlive) {
			killPoll();
		}
	}, [isExpanded, isPollAlive, killPoll, revivePoll]);

	const PoweredByText = () => (
		<TrackListPoweredBy>
			<div>
				<div>
					<p>
						Powered by
						<a
							href="https://www.seeqnc.com/live"
							rel="noopener noreferrer"
							target="_blank"
						>
							&nbsp;Seeqnc&nbsp;
						</a>
					</p>
					<p>
						Full Set list on&nbsp;
						<a
							href="https://www.seeqnc.com/live"
							target="_blank"
							rel="noopener noreferrer"
						>
							www.seeqnc.com/live
						</a>
					</p>
				</div>
				<div>
					<Image
						alt="Seeqnc Logo"
						src="./images/seeqnc_icon.png"
						width={24}
						height={24}
					/>
				</div>
			</div>
		</TrackListPoweredBy>
	);

	const TracksTable = () => (
		<LiveStreamTrackListBody isDesktop={isDesktop}>
			<Table liveStream={true}>
				{tracks.map((track: SeeqncTrack, index: number) => {
					const { lastPlay, track_id } = track;
					const trackPath = lastPlay.beatportUrl.replace(BEATPORT_URL, "/");
					const trackName = lastPlay.name;
					const trackRemix = lastPlay.mix;
					const trackImage = lastPlay.cover;
					const trackArtist = lastPlay.artistName;
					const trackId = track_id;
					const isAlreadyInCart = isTrackInCart(trackId)?.inCart;

					return (
						<TableData key={index}>
							<TableRow liveStream={true}>
								<LiveStreamTrackListTableCell className="controls">
									<TrackArtworkWrapper
										width={IMG_WIDTH}
										height={IMG_HEIGHT}
									>
										<Link href={trackPath} prefetch={false} title={trackName} className="artwork">
											<ImageFallback
												src={trackImage}
												srcSize="sm"
												alt={trackName}
												width={IMG_WIDTH}
												height={IMG_HEIGHT}
												fallbackSrc={FALLBACK_URL}
												blurDataURL={FALLBACK_URL}
											/>
										</Link>
									</TrackArtworkWrapper>
								</LiveStreamTrackListTableCell>
								<LiveStreamTrackListTableCell className="cell title">
									<div className="container">
										<Link href={trackPath} prefetch={false} title={trackName}>
											<ReleaseName>
												{trackName} <span>{trackRemix}</span>
											</ReleaseName>
										</Link>
										<TrackListArtistNames>
											<ArtistNames liveStream>{trackArtist}</ArtistNames>
										</TrackListArtistNames>
									</div>
								</LiveStreamTrackListTableCell>
								<LiveStreamTrackListTableCell className="cell title">
									<AddToCart trackId={trackId}>
										<AddToCartChild isAlreadyInCart={isAlreadyInCart}>
											<SpriteIcon
												id="shopping-cart"
												fill="none"
												width={isDesktop ? "16px" : "24px"}
												height={isDesktop ? "16px" : "24px"}
											/>
										</AddToCartChild>
									</AddToCart>
								</LiveStreamTrackListTableCell>
							</TableRow>
						</TableData>
					);
				})}
			</Table>
		</LiveStreamTrackListBody>
	);

	return (
		<LiveStreamWrapper>
			<LiveStreamTrackListWrapper>
				<LiveStreamTrackListHeader id={HEADER_ID}>
					<div>
						<EventTitle>{currentStream.name || "Beatport Live"}</EventTitle>
						<CurrentPlayingTrack>On Stage: {currentArtist}</CurrentPlayingTrack>
					</div>
					<PoweredByText />
				</LiveStreamTrackListHeader>
				<TracksTable />
			</LiveStreamTrackListWrapper>
		</LiveStreamWrapper>
	);
};

export default LiveStreamTrack;
